import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Der Sports Zone Pub
			</title>
			<meta name={"description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:title"} content={"Der Sports Zone Pub"} />
			<meta property={"og:description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="20px 0 20px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" align-items="center" justify-content="space-between" />
			<Link
				white-space="nowrap"
				border-style="solid"
				padding="6px 0px 6px 0px"
				lg-margin="0px 16px 0px 0px"
				color="--primary"
				hover-border-color="--color-primary"
				border-width="1px 0px 0px 0px"
				href="/"
				transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
				border-color="rgba(255, 255, 255, 0)"
				margin="0px 16px 0px 16px"
				md-margin="0px 0px 7px 0px"
				font="--headline5"
				letter-spacing="1.3px"
				text-decoration-line="initial"
				font-style="italic"
			>
				The Sports Zone Pub
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="--color-darkL2" padding="0px 0px 0px 0px" />
				<Override slot="Button Icon" md-color="--light" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Link
						border-style="solid"
						text-decoration-line="initial"
						padding="6px 0px 6px 0px"
						hover-color="--light"
						lg-margin="0px 16px 0px 0px"
						color="--light"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/about"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-color="rgba(255, 255, 255, 0)"
						font="--base"
						margin="0px 16px 0px 16px"
						md-margin="0px 0px 7px 0px"
					>
						Über uns
					</Link>
					<Link
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/features"
						padding="6px 0px 6px 0px"
						margin="0px 16px 0px 16px"
						text-decoration-line="initial"
						hover-border-color="--color-primary"
						border-style="solid"
						color="--light"
						border-width="1px 0px 0px 0px"
						lg-margin="0px 16px 0px 0px"
						md-margin="0px 0px 7px 0px"
						font="--base"
						hover-color="--light"
						border-color="rgba(255, 255, 255, 0)"
					>
						Menu
					</Link>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--light"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/testimonials"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="--base"
					>
						Rezensionen
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Reviews-3">
			<Text
				margin="0px 0px 20px 0px"
				font="--lead"
				color="--darkL2"
				lg-margin="0px 0px 20px 0px"
				text-align="center"
			>
				Nichts spricht besser für **The Sports Zone Pub, als zufriedene Kunden
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				font="--headline5"
				color="--darkL2"
				lg-margin="0px 0px 20px 0px"
				text-align="center"
			>
				Bei The Sports Zone Pub schätzen wir Ihr Feedback und bemühen uns, Ihnen die bestmögliche Erfahrung zu bieten. Hier ist, was einige unserer Kunden zu sagen haben:
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center" md-margin="0px 0px 50px 0px">
				Kundenfeedback
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-rows="repeat(2, 1fr)"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 30px"
				justify-content="center"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-grid-gap="30px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="--color-light"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Ich komme schon seit Jahren in den Sports Zone Pub, und er enttäuscht mich nie! Die Atmosphäre ist an Spieltagen elektrisierend, das Personal ist immer freundlich, und das Essen ist absolut köstlich. Ich bin ein großer Fan von den gefüllten Nachos, und die Bierauswahl ist erstklassig. Machen Sie weiter so!
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13:33:06.087Z"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
							srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Von John D.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="--color-light url()"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Der Sports Zone Pub ist ein fantastischer Ort für Sportliebhaber. Die Bildschirme sind riesig, und es werden immer die wichtigsten Spiele gezeigt. Ich liebe die Kameradschaft unter den anderen Fans. An belebten Abenden kann es jedoch etwas laut werden, was die Unterhaltung erschwert. Außerdem wäre es schön, wenn es mehr vegetarische Optionen auf der Speisekarte gäbe.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13:33:06.087Z"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
							srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Von Sarah K.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="--color-light"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Dieser Ort ist ein verstecktes Juwel! Der Service hier ist außergewöhnlich. Das Personal ist aufmerksam und zuvorkommend, was jeden Besuch zu einem Vergnügen macht. Der Büffelhühnersalat ist mein Favorit, und der Old Fashioned ist ein echter Renner. Ich schätze die Mühe, die sie sich machen, um eine einladende Umgebung für alle zu schaffen.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13:33:06.087Z"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
							srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Von David M.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="--color-light"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							Ich kann nicht genug Gutes über The Sports Zone Pub sagen. Die Begeisterung des Personals für den Sport ist ansteckend, und man fühlt sich sofort wie zu Hause. Die Burger hier gehören zu den besten, die ich je gegessen habe, und die Happy-Hour-Angebote sind unschlagbar. Es ist meine erste Adresse, um mit Freunden das Spiel zu sehen.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						md-min-width={0}
						md-min-height={0}
					>
						<Image
							src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13:33:06.087Z"
							display="block"
							margin="0px 25px 0px 0px"
							width="80px"
							height="80px"
							border-radius="50%"
							border-width="1px"
							border-style="solid"
							border-color="#b0b7bb"
							object-fit="cover"
							md-margin="0px 15px 0px 0px"
							md-width="68px"
							md-height="68px"
							srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/avatar.png?v=2023-10-11T13%3A33%3A06.087Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
								Von Mike W.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="20px 0 20px 0" lg-height="auto" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Box
					margin="28px 0px 0px 0px"
					display="flex"
					md-margin="28px 0px 0px 0px"
					width="100% border-box"
					text-align="center"
					justify-content="center"
				>
					<Link
						color="--primary"
						font="--base"
						href="#"
						padding="6px 0px 6px 0px"
						margin="0px 32px 0px 0px"
						border-style="solid"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-color="rgba(255, 255, 255, 0)"
						text-decoration-line="initial"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						lg-margin="0px 16px 0px 0px"
					>
						Rosa-Luxemburg-Straße 41, 10178 Berlin, Germany
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--darkL1"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="tel:+493081453960"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						+493081453960
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="mailto:info@bukizuy.com"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						info@bukizuy.com
					</Link>
				</Box>
				<Box
					margin="28px 0px 28px 0px"
					justify-content="center"
					text-align="center"
					display="flex"
					lg-margin="24px 0px 16px 0px"
				>
					<Text
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-style="solid"
						border-color="rgba(255, 255, 255, 0)"
						margin="0px 32px 0px 0px"
						hover-color="--primary"
						lg-margin="0px 16px 0px 0px"
						color="--primary"
						font="--base"
						padding="6px 0px 6px 0px"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Copyright 2023.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});