export default {
	"pages": {
		"root": {
			"name": "root",
			"children": [
				"6526932bcd81ee001f7d421f",
				"6526932bcd81ee001f7d4223",
				"6526932bcd81ee001f7d4227",
				"6526932bcd81ee001f7d422b",
				"6526932bcd81ee001f7d422f"
			],
			"id": "root",
			"pageUrl": "root"
		},
		"6526932bcd81ee001f7d421f": {
			"id": "6526932bcd81ee001f7d421f",
			"name": "404",
			"pageUrl": "404"
		},
		"6526932bcd81ee001f7d4223": {
			"id": "6526932bcd81ee001f7d4223",
			"name": "index",
			"pageUrl": "index"
		},
		"6526932bcd81ee001f7d4227": {
			"id": "6526932bcd81ee001f7d4227",
			"pageUrl": "about",
			"name": "About"
		},
		"6526932bcd81ee001f7d422b": {
			"id": "6526932bcd81ee001f7d422b",
			"pageUrl": "features",
			"name": "Features"
		},
		"6526932bcd81ee001f7d422f": {
			"id": "6526932bcd81ee001f7d422f",
			"pageUrl": "testimonials",
			"name": "Testimonials"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "--color-dark",
			"font": "16px --fontFamily-googleTeko"
		},
		"seo": {
			"background_win10": "#232323",
			"title": "Der Sports Zone Pub",
			"description": "The Sports Zone Pub - wir leben und atmen den Sport!",
			"og:title": "Der Sports Zone Pub",
			"og:description": "The Sports Zone Pub - wir leben und atmen den Sport!",
			"favicon_apple_152px": "https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z",
			"og:image": "https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z",
			"favicon_32px": "https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z",
			"customSitemapDomain": "https://bukizuy.com/"
		}
	}
}