import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Der Sports Zone Pub
			</title>
			<meta name={"description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:title"} content={"Der Sports Zone Pub"} />
			<meta property={"og:description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="20px 0 20px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" align-items="center" justify-content="space-between" />
			<Link
				white-space="nowrap"
				border-style="solid"
				padding="6px 0px 6px 0px"
				lg-margin="0px 16px 0px 0px"
				color="--primary"
				hover-border-color="--color-primary"
				border-width="1px 0px 0px 0px"
				href="/"
				transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
				border-color="rgba(255, 255, 255, 0)"
				margin="0px 16px 0px 16px"
				md-margin="0px 0px 7px 0px"
				font="--headline5"
				letter-spacing="1.3px"
				text-decoration-line="initial"
				font-style="italic"
			>
				The Sports Zone Pub
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="--color-darkL2" padding="0px 0px 0px 0px" />
				<Override slot="Button Icon" md-color="--light" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Link
						border-style="solid"
						text-decoration-line="initial"
						padding="6px 0px 6px 0px"
						hover-color="--light"
						lg-margin="0px 16px 0px 0px"
						color="--light"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/about"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-color="rgba(255, 255, 255, 0)"
						font="--base"
						margin="0px 16px 0px 16px"
						md-margin="0px 0px 7px 0px"
					>
						Menu
					</Link>
					<Link
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/features"
						padding="6px 0px 6px 0px"
						margin="0px 16px 0px 16px"
						text-decoration-line="initial"
						hover-border-color="--color-primary"
						border-style="solid"
						color="--light"
						border-width="1px 0px 0px 0px"
						lg-margin="0px 16px 0px 0px"
						md-margin="0px 0px 7px 0px"
						font="--base"
						hover-color="--light"
						border-color="rgba(255, 255, 255, 0)"
					>
						Vorteile
					</Link>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--light"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/testimonials"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="--base"
					>
						Rezensionen
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section background="--color-dark" quarkly-title="About-8">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				background="linear-gradient(240deg,rgba(0,0,0,0) 0%,--color-darkL2 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/6.2.jpg?v=2023-10-11T12:34:53.459Z) 0% 0% /cover repeat scroll padding-box"
				lg-width="100%"
				sm-padding="32px 32px 32px 32px"
				lg-padding="48px 64px 90px 64px"
				md-padding="48px 48px 90px 48px"
				padding="90px 60px 90px 64px"
				width="100% border-box"
			>
				<Text
					margin="0px 0px 24px 0px"
					font="--headline1"
					color="--secondary"
					sm-font="--headline3"
					max-width="80%"
					md-font="normal 700 60px/1.2 &quot;Mukta&quot;, sans-serif"
				>
					The Sports Zone Pub - wir leben und atmen Sport!
				</Text>
			</Box>
			<Box
				width="5%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				position="relative"
				justify-content="center"
				lg-width="100%"
				lg-justify-content="flex-start"
				background="--color-primary"
			/>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-darkL2"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				The Sports Zone Pub ist mehr als nur eine Sportbar; wir sind ein Zufluchtsort für Sportbegeisterte und Feinschmecker gleichermaßen. Mit unserer Leidenschaft für den Sport und unserem Engagement für ein außergewöhnliches Ess- und Unterhaltungserlebnis sind wir zu einem lokalen Favoriten geworden.
			</Text>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12:34:53.454Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					text-align="left"
					srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/4.2.jpg?v=2023-10-11T12%3A34%3A53.454Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-darkL2"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 15px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
					border-color="--color-primary"
					align-self="flex-start"
				>
					Unsere Geschichte
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--headline5"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					border-color="--color-primary"
				>
					The Sports Zone Pub wurde von einer Gruppe von Sportbegeisterten mit einem Ziel vor Augen gegründet: das ultimative Ziel für Sportfans zu schaffen. Unseren Gründern schwebte ein Ort vor, an dem Menschen zusammenkommen können, um ihre Lieblingsmannschaften zu feiern, die Aufregung des Spieltags zu teilen und köstliche Speisen und Getränke in einer einladenden Atmosphäre zu genießen.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0">
			<Override slot="SectionContent" align-items="flex-end" />
			<Text
				color="--darkL1"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlelightaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				Unser Auftrag
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL1" font="--lead" text-align="left">
					<Text font="--headline4" display="inline-block">
						Unsere Mission ist einfach:
					</Text>
					{" "}einen Ort zu schaffen, an dem Freunde und Familien über ihre gemeinsame Liebe zum Sport zusammenkommen können. Wir bieten erstklassigen Service, eine abwechslungsreiche Speisekarte für jeden Geschmack und eine Umgebung, in der sich jeder als Teil unserer sportbegeisterten Gemeinschaft fühlt.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Was uns auszeichnet
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						1
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Leidenschaft für Sport:
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Unser Team ist leidenschaftlich für alles, was mit Sport zu tun hat, und das zeigt sich in der Art und Weise, wie wir unsere Veranstaltungen kuratieren und ein fesselndes Sporterlebnis schaffen.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						2
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Leckeres Essen
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Wir sind stolz auf unsere Speisekarte, die eine Reihe von köstlichen Gerichten bietet, von klassischer Pub-Kost bis hin zu gesünderen Optionen, die mit Sorgfalt und hochwertigen Zutaten zubereitet werden.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						3
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Friendly Atmosphere
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Die Sports Zone Pub ist mehr als nur eine Sportbar; es ist ein Ort, wo Stammgäste zu Freunden werden, und Neuankömmlinge sind mit offenen Armen empfangen.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						4
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Gemeinschaftliches Engagement
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Wir engagieren uns aktiv in unserer lokalen Gemeinschaft und veranstalten Wohltätigkeitsveranstaltungen, Spendenaktionen und Watch Parties, die Menschen für einen guten Zweck zusammenbringen.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						5
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Spannung am Spieltag
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Es gibt keinen besseren Ort, um ein Spiel zu sehen. Wir haben mehrere große Bildschirme, Spieltag-Specials und eine elektrisierende Atmosphäre, die die Spannung jedes Spiels noch verstärkt.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(240deg,rgba(0,0,0,0) 0%,--color-darkL2 100%),--color-dark url(https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/3.1.jpg?v=2023-10-11T12:34:53.497Z) 50% 15%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					margin="0 0 30px 0"
					text-align="center"
					letter-spacing="1.4px"
					text-transform="uppercase"
					font="--headline5"
				>
					Egal, ob Sie ein leidenschaftlicher Sportfan sind, ein Feinschmecker auf der Suche nach leckeren Häppchen oder einfach nur ein Ort, an dem Sie sich mit Freunden amüsieren können - The Sports Zone Pub ist da, um jeden Besuch unvergesslich zu machen. Kommen Sie zu uns und werden Sie ein Teil unserer sportbegeisterten Familie!
				</Text>
			</Box>
		</Section>
		<Section padding="20px 0 20px 0" lg-height="auto" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Box
					margin="28px 0px 0px 0px"
					display="flex"
					md-margin="28px 0px 0px 0px"
					width="100% border-box"
					text-align="center"
					justify-content="center"
				>
					<Link
						color="--primary"
						font="--base"
						href="#"
						padding="6px 0px 6px 0px"
						margin="0px 32px 0px 0px"
						border-style="solid"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-color="rgba(255, 255, 255, 0)"
						text-decoration-line="initial"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						lg-margin="0px 16px 0px 0px"
					>
						Rosa-Luxemburg-Straße 41, 10178 Berlin, Germany
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--darkL1"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="tel:+493081453960"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						+493081453960
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="mailto:info@bukizuy.com"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						info@bukizuy.com
					</Link>
				</Box>
				<Box
					margin="28px 0px 28px 0px"
					justify-content="center"
					text-align="center"
					display="flex"
					lg-margin="24px 0px 16px 0px"
				>
					<Text
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-style="solid"
						border-color="rgba(255, 255, 255, 0)"
						margin="0px 32px 0px 0px"
						hover-color="--primary"
						lg-margin="0px 16px 0px 0px"
						color="--primary"
						font="--base"
						padding="6px 0px 6px 0px"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Copyright 2023.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});