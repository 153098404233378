import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"features"} />
		<Helmet>
			<title>
				Der Sports Zone Pub
			</title>
			<meta name={"description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:title"} content={"Der Sports Zone Pub"} />
			<meta property={"og:description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="20px 0 20px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" align-items="center" justify-content="space-between" />
			<Link
				white-space="nowrap"
				border-style="solid"
				padding="6px 0px 6px 0px"
				lg-margin="0px 16px 0px 0px"
				color="--primary"
				hover-border-color="--color-primary"
				border-width="1px 0px 0px 0px"
				href="/"
				transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
				border-color="rgba(255, 255, 255, 0)"
				margin="0px 16px 0px 16px"
				md-margin="0px 0px 7px 0px"
				font="--headline5"
				letter-spacing="1.3px"
				text-decoration-line="initial"
				font-style="italic"
			>
				The Sports Zone Pub
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="--color-darkL2" padding="0px 0px 0px 0px" />
				<Override slot="Button Icon" md-color="--light" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Link
						border-style="solid"
						text-decoration-line="initial"
						padding="6px 0px 6px 0px"
						hover-color="--light"
						lg-margin="0px 16px 0px 0px"
						color="--light"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/about"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-color="rgba(255, 255, 255, 0)"
						font="--base"
						margin="0px 16px 0px 16px"
						md-margin="0px 0px 7px 0px"
					>
						Über uns
					</Link>
					<Link
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/features"
						padding="6px 0px 6px 0px"
						margin="0px 16px 0px 16px"
						text-decoration-line="initial"
						hover-border-color="--color-primary"
						border-style="solid"
						color="--light"
						border-width="1px 0px 0px 0px"
						lg-margin="0px 16px 0px 0px"
						md-margin="0px 0px 7px 0px"
						font="--base"
						hover-color="--light"
						border-color="rgba(255, 255, 255, 0)"
					>
						Menu
					</Link>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--light"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/testimonials"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="--base"
					>
						Rezensionen
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-secondary"
						color="--dark"
					>
						<Text
							as="h2"
							margin="12px 0"
							font="--headline4"
							md-font="--headline3"
							color="--darkL2"
						>
							Menü-Highlights von The Sports Zone Pub
						</Text>
						<Text margin="12px 0" font="--lead" color="--darkL2">
							- Vorspeisen (Crispy Chicken Wings, Loaded Nachos, Mozzarella Sticks, Spinat Artischocken Dip).
							<br />
							- Burger (Classic Cheeseburger, BBQ Bacon Burger, Veggie Burger, The Ultimate Burger (mit allen Toppings)).
							<br />
							- Sandwiches (Philly Cheesesteak, Gegrillter Chicken Club, BLT, Truthahn-Avocado-Wrap).
							<br />
							- Salate (Caesar Salad, Garden Fresh Salad, Buffalo Chicken Salad, Cobb Salad).
							<br />
							- Pizza (Pizza Margherita, Pizza für Fleischliebhaber, Pizza Veggie Delight, Pizza mit Grillhähnchen).
							<br />
							- Essen (Gegrillter Lachs, Baby Back Ribs, Chicken Tenders, Fisch und Pommes).
							<br />
							- Beilagen (Pommes frites, Zwiebelringe, Krautsalat, Knoblauch-Parmesan-Kartoffelspalten).
							<br />
							- Desserts (Schoko-Lavakuchen, New Yorker Käsekuchen, Eisbecher, Apfelkuchen a la mode).
							<br />
							- Getränke (Fassbiere (fragen Sie nach unserer aktuellen Auswahl), Craft-Cocktails, Weinkarte (Rot-, Weiß- und Schaumweine), Softdrinks und Eistee).
							<br />
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://images.unsplash.com/photo-1619810816144-223f5b027aea?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://images.unsplash.com/photo-1618183479302-1e0aa382c36b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://images.unsplash.com/photo-1579065472192-c3f98f86b6c6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="20px 0 20px 0" lg-height="auto" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Box
					margin="28px 0px 0px 0px"
					display="flex"
					md-margin="28px 0px 0px 0px"
					width="100% border-box"
					text-align="center"
					justify-content="center"
				>
					<Link
						color="--primary"
						font="--base"
						href="#"
						padding="6px 0px 6px 0px"
						margin="0px 32px 0px 0px"
						border-style="solid"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-color="rgba(255, 255, 255, 0)"
						text-decoration-line="initial"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						lg-margin="0px 16px 0px 0px"
					>
						Rosa-Luxemburg-Straße 41, 10178 Berlin, Germany
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--darkL1"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="tel:+493081453960"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						+493081453960
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="mailto:info@bukizuy.com"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						info@bukizuy.com
					</Link>
				</Box>
				<Box
					margin="28px 0px 28px 0px"
					justify-content="center"
					text-align="center"
					display="flex"
					lg-margin="24px 0px 16px 0px"
				>
					<Text
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-style="solid"
						border-color="rgba(255, 255, 255, 0)"
						margin="0px 32px 0px 0px"
						hover-color="--primary"
						lg-margin="0px 16px 0px 0px"
						color="--primary"
						font="--base"
						padding="6px 0px 6px 0px"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Copyright 2023.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});