import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Der Sports Zone Pub
			</title>
			<meta name={"description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:title"} content={"Der Sports Zone Pub"} />
			<meta property={"og:description"} content={"The Sports Zone Pub - wir leben und atmen den Sport!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/football-icon-design-free-png.webp?v=2023-10-11T12:33:21.585Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="20px 0 20px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" align-items="center" justify-content="space-between" />
			<Link
				white-space="nowrap"
				border-style="solid"
				padding="6px 0px 6px 0px"
				lg-margin="0px 16px 0px 0px"
				color="--primary"
				hover-border-color="--color-primary"
				border-width="1px 0px 0px 0px"
				href="/"
				transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
				border-color="rgba(255, 255, 255, 0)"
				margin="0px 16px 0px 16px"
				md-margin="0px 0px 7px 0px"
				font="--headline5"
				letter-spacing="1.3px"
				text-decoration-line="initial"
				font-style="italic"
			>
				The Sports Zone Pub
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="--color-darkL2" padding="0px 0px 0px 0px" />
				<Override slot="Button Icon" md-color="--light" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Link
						border-style="solid"
						text-decoration-line="initial"
						padding="6px 0px 6px 0px"
						hover-color="--light"
						lg-margin="0px 16px 0px 0px"
						color="--light"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/about"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-color="rgba(255, 255, 255, 0)"
						font="--base"
						margin="0px 16px 0px 16px"
						md-margin="0px 0px 7px 0px"
					>
						Über uns
					</Link>
					<Link
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/features"
						padding="6px 0px 6px 0px"
						margin="0px 16px 0px 16px"
						text-decoration-line="initial"
						hover-border-color="--color-primary"
						border-style="solid"
						color="--light"
						border-width="1px 0px 0px 0px"
						lg-margin="0px 16px 0px 0px"
						md-margin="0px 0px 7px 0px"
						font="--base"
						hover-color="--light"
						border-color="rgba(255, 255, 255, 0)"
					>
						Menu
					</Link>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--light"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						href="/testimonials"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="--base"
					>
						Rezensionen
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Über uns
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Im The Sports Zone Pub leben und atmen wir den Sport. Egal, ob Sie ein eingefleischter Fan sind oder einfach nur einen großartigen Ort suchen, um das Spiel mit Freunden zu sehen - wir haben alles für Sie. Unser Pub ist das ultimative Ziel für Sportbegeisterte und bietet eine lebendige Atmosphäre, leckeres Essen und eine große Auswahl an Getränken.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Unsere Atmosphäre
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Wenn Sie The Sports Zone Pub betreten, spüren Sie sofort die Energie. Mit mehreren Großbildfernsehern, die strategisch im gesamten Lokal platziert sind, verpassen Sie keinen Moment der Action. Unsere bequemen Sitzgelegenheiten und unser freundliches Personal sorgen dafür, dass Sie bei jedem Besuch ein unvergessliches Erlebnis haben werden.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12:34:53.453Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.1.jpg?v=2023-10-11T12%3A34%3A53.453Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1550950158-d0d960dff51b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Menü
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Unsere Speisekarte bietet eine Vielzahl von köstlichen Optionen, die Ihren Geschmacksnerven schmeicheln. Von klassischen Kneipengerichten wie Wings und Burgern bis hin zu gesünderen Varianten wie Salaten und Gegrilltem ist für jeden etwas dabei. Kombinieren Sie Ihre Mahlzeit mit einem kühlen Bier vom Fass, einem typischen Cocktail oder einem alkoholfreien Getränk aus unserer umfangreichen Getränkekarte.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Events und Specials
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Wir nehmen Sport sehr ernst und veranstalten deshalb spezielle Events zu wichtigen Spielen, Meisterschaften und Turnieren. Achten Sie auf unseren Veranstaltungskalender für Spieltag-Specials, Trivia-Abende und mehr. Außerdem bieten wir unter der Woche Happy Hour-Angebote an, bei denen Sie hervorragende Speisen und Getränke zu unschlagbaren Preisen genießen können.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12:34:53.460Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6526932bcd81ee001f7d4201/images/1.3.jpg?v=2023-10-11T12%3A34%3A53.460Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--darkL2"
			>
				Private Feiern
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Suchen Sie nach einem Ort für Ihre nächste private Veranstaltung oder eine Fernsehparty? Der Sports Zone Pub ist die perfekte Wahl. Unser privater Partybereich bietet Platz für Gruppen jeder Größe, und wir bieten maßgeschneiderte Pakete für Ihre speziellen Bedürfnisse.
			</Text>
		</Section>
		<Section padding="20px 0 20px 0" lg-height="auto" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Box
					margin="28px 0px 0px 0px"
					display="flex"
					md-margin="28px 0px 0px 0px"
					width="100% border-box"
					text-align="center"
					justify-content="center"
				>
					<Link
						color="--primary"
						font="--base"
						href="#"
						padding="6px 0px 6px 0px"
						margin="0px 32px 0px 0px"
						border-style="solid"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-primary"
						border-color="rgba(255, 255, 255, 0)"
						text-decoration-line="initial"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						lg-margin="0px 16px 0px 0px"
					>
						Rosa-Luxemburg-Straße 41, 10178 Berlin, Germany
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--darkL1"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="tel:+493081453960"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						+493081453960
					</Link>
					<Link
						color="--primary"
						padding="6px 0px 6px 0px"
						hover-color="--primary"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-width="1px 0px 0px 0px"
						border-color="rgba(255, 255, 255, 0)"
						href="mailto:info@bukizuy.com"
						text-decoration-line="initial"
						font="--base"
						margin="0px 32px 0px 0px"
						hover-border-color="--color-primary"
						border-style="solid"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						info@bukizuy.com
					</Link>
				</Box>
				<Box
					margin="28px 0px 28px 0px"
					justify-content="center"
					text-align="center"
					display="flex"
					lg-margin="24px 0px 16px 0px"
				>
					<Text
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						border-style="solid"
						border-color="rgba(255, 255, 255, 0)"
						margin="0px 32px 0px 0px"
						hover-color="--primary"
						lg-margin="0px 16px 0px 0px"
						color="--primary"
						font="--base"
						padding="6px 0px 6px 0px"
						hover-border-color="--color-primary"
						border-width="1px 0px 0px 0px"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Copyright 2023.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});